/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-northeast-1",
    "aws_cloud_logic_custom": [
        {
            "name": "biztechRestApi",
            "endpoint": "https://mlkcom8dic.execute-api.ap-northeast-1.amazonaws.com/prd",
            "region": "ap-northeast-1"
        }
    ]
};


export default awsmobile;
