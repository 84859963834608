import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { API } from "aws-amplify";
import Box from "@mui/material/Box";
import Loading from "./utils/Loading";
import { useTorus } from "../hooks/torusWalletConnect";
import ClaimNftForm from "./ClaimNftForm";
import MedalList from "./MedalList";
import CopyProfile from "./CopyProfile";

const Home = () => {
  const navigate = useNavigate();
  const { walletAddress, signMessage } = useTorus();
  const [membershipNft, setMembershipNft] = useState();
  const [isClaim, setIsClaim] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isMedalLoading, setIsMedalLoading] = useState(false);

  const fetchMembershipNft = async ({ walletAddress }) => {
    setIsLoading(true);
    console.log(walletAddress);
    const res = await API.get("biztechRestApi", "/membership", {
      queryStringParameters: {
        walletAddress,
      },
    });
    console.log(res);
    if (res.verified && res.isMember) {
      setMembershipNft(res.nft);
    }
    setIsClaim(res.isClaim);
    if (res.status === "BURN") {
      setIsClaim(false);
    }
    if (res.status === "WITHDRAWAL") {
      navigate("/withdrawal/complete");
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (!walletAddress) {
      return;
    }
    fetchMembershipNft({ walletAddress });
  }, [walletAddress]);

  return (
    <>
      {isLoading ? (
        <>
          <Loading />
        </>
      ) : (
        <>
          {membershipNft ? (
            <>
              <Box
                sx={{
                  m: 3,
                  display: "flex",
                  justifyContent: "center",
                  mb: 6,
                }}
              >
                <Box
                  component="img"
                  alt="MembershipNFT"
                  src={membershipNft.metadata.image}
                  sx={{
                    width: 400,
                    maxWidth: "90%",
                  }}
                />
              </Box>
              {isMedalLoading ? (
                <>
                  <Loading />
                </>
              ) : (
                <>
                  <CopyProfile walletAddress={walletAddress} />
                  <MedalList walletAddress={walletAddress} />
                </>
              )}
            </>
          ) : (
            <>
              <ClaimNftForm isClaim={isClaim} />
            </>
          )}
        </>
      )}
    </>
  );
};

export default Home;
