import { createContext, useContext, useEffect, useState } from "react";
import Torus from "@toruslabs/torus-embed";
import { ethers } from "ethers";
import { API } from "aws-amplify";

/**
 * torus context
 * どこでも使えるようにuseContextで定義している
 */
export const TorusContext = createContext({
  torus: null,
  provider: null,
  walletAddress: null,
  login: async () => {},
  logout: async () => {},
  signMessage: async () => {},
  isInitializing: false,
});

export const useTorus = () => {
  return useContext(TorusContext);
};

export const TorusProvider = ({ children }) => {
  const [torus, setTorus] = useState(null);
  const [provider, setProvider] = useState(null);
  const [walletAddress, setWalletAddress] = useState(null);
  const [isInitializing, setIsInitializing] = useState(true);

  /**
   * Torus 初期化処理
   */
  const initTorus = async () => {
    try {
      setIsInitializing(true);
      const torus = new Torus();
      await torus.init({
        showTorusButton: false,
        useLocalStorage: true,
      });
      setTorus(torus);
      setIsInitializing(false);
      return torus;
    } catch (error) {
      console.error(error);
      API.post("biztechRestApi", "/log", {
        body: {
          key: "initTorus error",
          errorName: error.name,
          errorMessage: error.message,
          error: error.stack,
        },
      });
      return null;
    }
  };

  // torus初期化
  useEffect(() => {
    initTorus();
  }, []);

  // wallet addressのセット
  useEffect(() => {
    const updateWalletAddress = async () => {
      const account = await getAccount();
      setWalletAddress(account);

      API.post("biztechRestApi", "/log", {
        body: {
          key: "torus login success",
          message: { wallet: account },
        },
      });
    };
    updateWalletAddress();
  }, [provider]);

  /**
   * 接続
   */
  const login = async () => {
    if (!torus) {
      console.log("torus not initialized yet");
      return;
    }
    try {
      await torus.login();
      const provider = new ethers.providers.Web3Provider(torus.provider);
      setProvider(provider);
    } catch (error) {
      API.post("biztechRestApi", "/log", {
        body: {
          key: "torus login error",
          errorName: error.name,
          errorMessage: error.message,
          error: error.stack,
        },
      });
      throw error;
    }
  };

  /**
   * 接続解除
   */
  const logout = async () => {
    if (!torus) {
      console.log("torus not initialized yet");
      return;
    }
    await torus.logout();
    setProvider(null);
  };

  /**
   * アドレス取得
   */
  const getAccount = async () => {
    if (!provider) {
      console.log("provider not initialized yet");
      return;
    }
    const signer = provider.getSigner();
    return await signer.getAddress();
  };

  /**
   * アドレス取得
   */
  const signMessage = async (message) => {
    if (!provider) {
      console.log("provider not initialized yet");
      return;
    }
    const signer = provider.getSigner();
    return await signer.signMessage(message);
  };

  const contextProvider = {
    torus,
    provider,
    walletAddress,
    login,
    logout,
    signMessage,
    isInitializing,
  };
  return (
    <TorusContext.Provider value={contextProvider}>
      {children}
    </TorusContext.Provider>
  );
};
