import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { API } from "aws-amplify";
import Box from "@mui/material/Box";
import Loading from "./utils/Loading";
import ClaimNftForm from "./ClaimNftForm";
import MedalList from "./MedalList";

const Profile = () => {
  const navigate = useNavigate();
  const { walletAddress } = useParams();
  const [membershipNft, setMembershipNft] = useState();
  const [isClaim, setIsClaim] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isMedalLoading, setIsMedalLoading] = useState(false);

  const fetchMembershipNft = async ({ walletAddress }) => {
    setIsLoading(true);
    console.log(walletAddress);
    const res = await API.get("biztechRestApi", "/membership", {
      queryStringParameters: {
        walletAddress,
      },
    });
    console.log(res);
    if (res.verified && res.isMember) {
      setMembershipNft(res.nft);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (!walletAddress) {
      return;
    }
    fetchMembershipNft({ walletAddress });
  }, [walletAddress]);

  return (
    <>
      {isLoading ? (
        <>
          <Loading />
        </>
      ) : (
        <>
          {membershipNft && (
            <>
              <Box
                sx={{
                  m: 3,
                  display: "flex",
                  justifyContent: "center",
                  mb: 6,
                }}
              >
                <Box
                  component="img"
                  alt="MembershipNFT"
                  src={membershipNft.metadata.image}
                  sx={{
                    width: 400,
                    maxWidth: "90%",
                  }}
                />
              </Box>
              {isMedalLoading ? (
                <>
                  <Loading />
                </>
              ) : (
                <>
                  <MedalList walletAddress={walletAddress} />
                </>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

export default Profile;
