import React, { useEffect } from "react";
import { useTorus } from "../hooks/torusWalletConnect";
import { Button, Box } from "@mui/material";
import Loading from "./utils/Loading";

const WalletConnect = () => {
  const { login, isInitializing } = useTorus();

  return (
    <>
      <Box sx={{ my: 15 }}>
        {isInitializing ? (
          <>
            <Loading />
          </>
        ) : (
          <>
            <Button
              variant="contained"
              onClick={login}
              sx={{
                color: "#FFFFFF",
                backgroundColor: "#000000",
                "&:hover": {
                  backgroundColor: "#000001",
                },
                height: 45,
                width: "80%",
                maxWidth: 300,
                borderRadius: 10,
                display: "block",
                margin: "auto",
                my: 2,
                boxShadow: 10,
                textTransform: "none",
                display: "flex",
                textAlign: "center",
                textJustify: "center",
              }}
            >
              サインイン／新規登録
            </Button>
          </>
        )}
      </Box>
    </>
  );
};

export default WalletConnect;
