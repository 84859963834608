import React, { useEffect, useState } from "react";
import { API } from "aws-amplify";
import { useTorus } from "../hooks/torusWalletConnect";
import {
  Button,
  Box,
  Divider,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";

const ClaimNftForm = ({ isClaim }) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const { walletAddress } = useTorus();
  const [isSubmit, setIsSubmit] = useState(isClaim);
  const [submitedName, setSubmitedName] = useState("");
  const [submitedEmail, setSubmitedEmail] = useState("");

  const validationRules = {
    name: {
      required: "お名前を入力してください。",
      maxLength: { value: 50, message: "50文字以下で入力してください。" },
    },
    email: {
      required: "メールアドレスを入力してください。",
      pattern: {
        value: /.+@.+\..+/,
        message: "メールアドレスの形式で入力してください。",
      },
    },
  };

  const claimRequest = async ({ name, email, walletAddress }) => {
    const res = await API.post("biztechRestApi", "/claim", {
      body: {
        name: name,
        email: email,
        walletAddress: walletAddress,
      },
    });
    console.log(res);
    setSubmitedName(name);
    setSubmitedEmail(email);
    setIsSubmit(true);
  };

  const onSubmit = (data) => {
    claimRequest({
      name: data.name,
      email: data.email,
      walletAddress: walletAddress,
    });
  };

  return (
    <>
      {isSubmit ? (
        <>
          <Box sx={{ mx: 3, my: 5 }}>
            <Typography sx={{ textAlign: "center" }}>
              NFT会員券請求リクエストを送信しました。
            </Typography>
            <Typography sx={{ textAlign: "center" }}>
              リクエストが承認された場合、NFT会員券は3営業日以内に送付されます。
            </Typography>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              {submitedName && submitedEmail && (
                <>
                  <Box sx={{ border: 1, my: 3 }}>
                    <Box
                      sx={{
                        bgcolor: "#FFF",
                        minWidth: "350px",
                        maxWidth: "500px",
                        width: "80%",
                        p: 3,
                      }}
                    >
                      <Grid container spacing={2}>
                        <Grid item xs={3}>
                          <Typography sx={{ fontWeight: "bold" }}>
                            お名前
                          </Typography>
                        </Grid>
                        <Grid item xs={9}>
                          <Typography>{submitedName}</Typography>
                        </Grid>
                      </Grid>
                      <Divider sx={{ my: 1 }} />
                      <Grid container spacing={2}>
                        <Grid item xs={3}>
                          <Typography sx={{ fontWeight: "bold" }}>
                            メール
                          </Typography>
                        </Grid>
                        <Grid item xs={9}>
                          <Typography>{submitedEmail}</Typography>
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                </>
              )}
            </Box>
          </Box>
        </>
      ) : (
        <>
          <Box sx={{ display: "flex", justifyContent: "center", my: 10 }}>
            <Stack component="form" noValidate onSubmit={handleSubmit(onSubmit)} spacing={2} sx={{ width: 350 }}>
              <Typography>Tech Forwardへ登録されている氏名、メールアドレスをご入力ください</Typography>
              <Controller
                name="name"
                control={control}
                rules={validationRules.name}
                render={({ field }) => (
                  <TextField
                    {...field}
                    type="text"
                    label="お名前"
                    error={errors.name !== undefined}
                    helperText={errors.name?.message}
                  />
                )}
              />
              <Controller
                name="email"
                control={control}
                rules={validationRules.email}
                render={({ field }) => (
                  <TextField
                    {...field}
                    type="email"
                    label="メールアドレス"
                    error={errors.email !== undefined}
                    helperText={errors.email?.message}
                  />
                )}
              />
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Button
                  variant="contained"
                  type="submit"
                  sx={{
                    color: "#FFFFFF",
                    backgroundColor: "#000000",
                    "&:hover": {
                      backgroundColor: "#000000",
                    },
                    height: 45,
                    width: "80%",
                    maxWidth: 300,
                    borderRadius: 10,
                    my: 2,
                    boxShadow: 10,
                    textTransform: "none",
                  }}
                >
                  NFT会員券を請求する
                </Button>
              </Box>
            </Stack>
          </Box>
        </>
      )}
    </>
  );
};

export default ClaimNftForm;
