import React from "react";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import MenuIcon from "@mui/icons-material/Menu";
import IconButton from "@mui/material/IconButton";
import { useTorus } from "../hooks/torusWalletConnect";
import HeaderImage from "../public/biztech.png";

function Hamburger() {
  const { provider, logout } = useTorus();
  const [state, setState] = React.useState({
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        <ListItem>
          <ListItemButton>
            <Box
              component="img"
              sx={{
                verticalAlign: "middle",
                width: "150px",
                m: "auto",
              }}
              alt="logo"
              src={HeaderImage}
            />
          </ListItemButton>
        </ListItem>
        {!provider && (
          <>
            <ListItem>
              <ListItemButton
                component={Link}
                to={"/"}
                sx={{
                  boxShadow: 10,
                  borderRadius: 10,
                  textTransform: "none",
                  display: "flex",
                  justifyContent: "center",
                  alignContent: "center",
                }}
              >
                サインイン／新規登録
              </ListItemButton>
            </ListItem>
          </>
        )}
        {/* 退会機能が不要になったのでコメントアウト */}
        {/* {provider && (
          <>
            <ListItem>
              <ListItemButton
                component={Link}
                to={"/withdrawal"}
                sx={{
                  boxShadow: 10,
                  borderRadius: 10,
                  textTransform: "none",
                  display: "flex",
                  justifyContent: "center",
                  alignContent: "center",
                }}
              >
                退会について
              </ListItemButton>
            </ListItem>
          </>
        )} */}
        <ListItem>
          <ListItemButton
            href={"https://tech-forward.io"}
            target="_blank"
            sx={{
              boxShadow: 10,
              borderRadius: 10,
              textTransform: "none",
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
            }}
          >
            Tech Forwardへ戻る
          </ListItemButton>
        </ListItem>
        <ListItem>
          <ListItemButton
            href={"https://tech-forward.io/magazine/techforward-nft"}
            target="_blank"
            sx={{
              boxShadow: 10,
              borderRadius: 10,
              textTransform: "none",
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
            }}
          >
            NFTについて
          </ListItemButton>
        </ListItem>
        {provider && (
          <>
            <ListItem>
              <ListItemButton
                component={Link}
                to={"/"}
                sx={{
                  boxShadow: 10,
                  borderRadius: 10,
                  textTransform: "none",
                  display: "flex",
                  justifyContent: "center",
                  alignContent: "center",
                }}
                onClick={logout}
              >
                サインアウト
              </ListItemButton>
            </ListItem>
          </>
        )}
      </List>
      <Divider />
    </Box>
  );

  return (
    <div>
      {["right"].map((anchor) => (
        <React.Fragment key={anchor}>
          <IconButton
            sx={{ maxWidth: 50 }}
            color="primary"
            aria-label="upload picture"
            component="label"
          >
            <Button onClick={toggleDrawer(anchor, true)}>
              <MenuIcon sx={{ color: "black", height: 60, width: 50 }} />
            </Button>
          </IconButton>
          <SwipeableDrawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
            onOpen={toggleDrawer(anchor, true)}
          >
            {list(anchor)}
          </SwipeableDrawer>
        </React.Fragment>
      ))}
    </div>
  );
}

export default Hamburger;
