import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { Container, Typography, Tooltip, Button } from "@mui/material";

export default function CopyProfile({ walletAddress }) {
  const [copyTip, setCopyTip] = useState(false);
  const copyClick = async () => {
    setCopyTip(true);
    navigator.clipboard.writeText(copyText);
    await new Promise((resolve) => setTimeout(resolve, 500));
    setCopyTip(false);
  };

  const copyText = `${window.location.origin}/profile/${walletAddress}`;

  return (
    <>
      <Container maxWidth="md" sx={{ mb: 5 }}>
        <Typography sx={{ textAlign: "center", mt: 2, fontSize: 14 }}>
          下記のURLで、保有のNFTをどなたでも閲覧できます。
        </Typography>
        <Typography sx={{ textAlign: "center", mb: 2, fontSize: 14 }}>
          Tech Forward WEB履歴書ページでもご利用可能です。
        </Typography>

        <Typography noWrap sx={{ textAlign: "center", my: 2, mx: 3, fontSize: 12 }}>
          {copyText}
        </Typography>
        <Tooltip
          title="Copied!!"
          disableFocusListener
          disableHoverListener
          disableInteractive
          disableTouchListener
          open={copyTip}
        >
          <Button
            variant="outlined"
            sx={{
              color: "#000000",
              height: 40,
              width: 140,
              borderRadius: 10,
              display: "block",
              margin: "auto",
              textTransform: "none",
            }}
            onClick={copyClick}
          >
            <Typography
              component="span"
              sx={{
                fontSize: 10,
              }}
            >
              リンクをコピー
            </Typography>
          </Button>
        </Tooltip>
      </Container>
    </>
  );
}
