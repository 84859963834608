import { createTheme } from "@mui/material/styles";
import "@fontsource/poppins/800.css";

const Poppins = createTheme({
  typography: {
    fontFamily:
      '"Poppins", "Helvetica Neue", "Helvetica", "Hiragino Sans", "Hiragino Kaku Gothic ProN", "Arial", "Yu Gothic", "Meiryo", sans-serif',
  },
});

export { Poppins };
