import React from "react";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";

export default function WithdrawalComplete() {
  return (
    <>
      <Box
        sx={{
          m: 3,
          mt: 5,
        }}
      >
        <Typography sx={{ textAlign: "center" }}>
          NFT会員 退会リクエストを送信しました。
        </Typography>
        <Typography sx={{ textAlign: "center" }}>
          リクエストが承認された場合、NFT会員券は3営業日以内にバーン(焼却)されます。
        </Typography>
      </Box>
    </>
  );
}
